import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import Checkbox from './Checkbox'
import Interval from './Interval'

interface Props {
  value: string[]
  onChange: Function
  options: FilterOption[]
  min: number
  max: number
  diagram: FilterDiagram[]
  filterText?: string
  filterName?: string
}

export default function FilterCheckboxInterval ({ value, onChange, options, min, max, diagram, filterText, filterName }: Props): JSX.Element {
  const intl = useIntl()
  const [intervalValue, setIntervalValue] = useState<string[]>(value.slice(0, 2))
  const [checkboxValue, setCheckboxValue] = useState<string[]>(value.slice(2))

  useEffect(() => {
    if (intervalValue.length > 0 && checkboxValue.length > 0) {
      onChange([...intervalValue.slice(0, 2), ...checkboxValue])
    } else {
      onChange([])
    }
  }, [intervalValue, checkboxValue])

  return (
    <div className="flex flex-col justify-start gap-10">
      <Interval
        diagram={diagram}
        filterName={filterName}
        filterText={filterText}
        max={max}
        min={min}
        onChange={function (e: string[]) { setIntervalValue(e) }}
        value={intervalValue}
      />

      {filterName === 'revenue_activities'
        ? (
          <div style={{ maxWidth: '800px' }}>
            {intl.formatMessage({ id: 'selectFilters.activity.info' })}
          </div>
          )
        : null}

      <Checkbox
        linked
        onChange={function (e: string[]) { setCheckboxValue(e) }}
        options={options}
        value={checkboxValue}
      />
    </div>
  )
}
